import { Card, Skeleton, Stack } from '@mui/material'
import type { FC } from 'react'

import { COLORS } from '../../../../constants'
import { unit } from '../../../../utils'
import type { CardVariant } from '../types'
import { CardEmbedment } from '../types'

import { cardEmbedmentCss, cardVariantCss, CONTENT_GAP, CONTENT_PADDING_X, CONTENT_PADDING_Y } from './styles'

interface CardPromotionLoadingProps {
  embedment: CardEmbedment
  variant: CardVariant
}

export const CardPromotionLoading: FC<CardPromotionLoadingProps> = ({ embedment, variant }) => {
  return (
    <Card
      sx={{ paddingX: unit(CONTENT_PADDING_X), paddingY: unit(CONTENT_PADDING_Y) }}
      css={[cardEmbedmentCss[embedment], cardVariantCss[variant]]}
    >
      <Stack gap={unit(CONTENT_GAP)}>
        {embedment === CardEmbedment.Carousel ? (
          <>
            <Skeleton width='95%' height={unit(10.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
            <Skeleton width='85%' height={unit(10.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
            <Skeleton width='90%' height={unit(10.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
            <Skeleton width='95%' height={unit(10.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
            <Skeleton width='85%' height={unit(10.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
          </>
        ) : (
          <>
            <Skeleton width='95%' height={unit(9.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
            <Skeleton width='85%' height={unit(9.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
            <Skeleton width='90%' height={unit(9.5)} sx={{ bgcolor: COLORS.white }} variant='rectangular' />
          </>
        )}
        <Skeleton width='80%' height={unit(6)} sx={{ bgcolor: COLORS.white }} />
      </Stack>
    </Card>
  )
}
