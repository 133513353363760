import { Box } from '@mui/material'
import type { FC } from 'react'

import { LoadingState, unit } from '@hcr/ui'

import { LayoutNavigation } from './LayoutNavigation'

interface LayoutNavigationLoadingProps {
  showContextMenuButton?: boolean
}

export const LayoutNavigationLoading: FC<LayoutNavigationLoadingProps> = ({ showContextMenuButton }) => {
  return (
    <LayoutNavigation showContextMenuButton={showContextMenuButton} stretchContentVertically>
      <Box paddingTop={unit(14)} height='100%'>
        <LoadingState />
      </Box>
    </LayoutNavigation>
  )
}
