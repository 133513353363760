import ABCGintoNordMediumOtf from './ABCGintoNord/ABCGintoNord-Medium.otf'
import ABCGintoNordMediumWoff from './ABCGintoNord/ABCGintoNord-Medium.woff'
import ABCGintoNordMediumWoff2 from './ABCGintoNord/ABCGintoNord-Medium.woff2'
import ABCGintoNordRegularOtf from './ABCGintoNord/ABCGintoNord-Regular.otf'
import ABCGintoNordRegularWoff from './ABCGintoNord/ABCGintoNord-Regular.woff'
import ABCGintoNordRegularWoff2 from './ABCGintoNord/ABCGintoNord-Regular.woff2'
import MoretBoldOtf from './Moret/Moret-Bold.otf'
import MoretRegularOtf from './Moret/Moret-Regular.otf'

export {
  ABCGintoNordMediumOtf,
  ABCGintoNordMediumWoff,
  ABCGintoNordMediumWoff2,
  ABCGintoNordRegularOtf,
  ABCGintoNordRegularWoff,
  ABCGintoNordRegularWoff2,
  MoretBoldOtf,
  MoretRegularOtf,
}
