import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { AccordionLoading } from '@hcr/ui'

export const BookingSummaryPriceLoading: FC = () => {
  const { t } = useTranslation()

  return <AccordionLoading>{t('booking-summary-price.price-breakdown')}</AccordionLoading>
}
